// src/components/LanguageSwitcher.js
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setLanguage } from 'features/user';

const LanguageSwitcher = () => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();
    const { language } = useSelector(state => state.user);
    const [lan, setLan] = useState(language ? language : "hr");


    useEffect(() => {
        const browserLanguage = navigator.language || navigator.userLanguage;
        if (browserLanguage.startsWith('hr') && language === '') {
            i18n.changeLanguage('hr');
            setLan('hr');
            dispatch(setLanguage('hr'));
            console.log("Hrvatski jezik");

        } else if (browserLanguage.startsWith('sk') && language === '') {
            i18n.changeLanguage('sk');
            setLan('sk');
            dispatch(setLanguage('sk'));
            console.log("slovački jezik");

        } else if (browserLanguage.startsWith('sv') && language === '') {
            i18n.changeLanguage('sv');
            setLan('sv');
            dispatch(setLanguage('sv'));
            console.log("svedski jezik");


            //defaultni jezik ako jezik nije manualno odabran    
        } else if (language === '') {
            i18n.changeLanguage('en');
            setLan('en');
            dispatch(setLanguage('en'));
            console.log("Engleski jezik");
        }

    }, [i18n]);

    useEffect(() => {
        if (language !== '' && language !== lan) {
            i18n.changeLanguage(language);
        }
    }, [dispatch, language]);

    const changeLanguageHc = (lng) => {
        i18n.changeLanguage(lng);
        dispatch(setLanguage(lng));
        setLan(lng);
    };

    return (
        <select name="switchbox" value={lan} onChange={(e) => changeLanguageHc(e.target.value)}>
            <option value="hr">Hr</option>
            <option value="en">En</option>
            <option value="sk">Sk</option>
            <option value="sv">Sv</option>
            {/* Add more languages here */}
        </select>
    );
};

export default LanguageSwitcher;
