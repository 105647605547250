import React from 'react';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

const QRCodeGeneratorLte = ({ maskedId, name, itemid }) => {
    const { t } = useTranslation();

    const downloadQRCode = () => {
        const canvas = document.getElementById(`qrCode-${maskedId}`);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `qrCode-${name}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const downloadQRCodeAsSVG = () => {
        const svgElement = document.getElementById(`qrCodeSVG-${maskedId}`);
        if (!svgElement) {
            console.error('SVG element not found');
            return;
        }
        
        // Serialize the SVG node to string
        const serializer = new XMLSerializer();
        const svgData = serializer.serializeToString(svgElement);

        // Create a Blob from the SVG data and initiate download
        const svgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
        const svgUrl = URL.createObjectURL(svgBlob);
        let downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = `qrCode-${name}.svg`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    // Placeholder for adding more static data
    
    //const qrData = `https://tinyurl.com/mr22au5h/item/${itemid}/${maskedId}/`
    const trim_mask = Number(maskedId);
    const trim_id = Number(itemid);

    const qrData = `https://tinyurl.com/4p99zes8/${trim_id}/${trim_mask}/`

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
            <h5 style={{fontSize: "14px", paddingBottom: "10px", marginTop: "10px"}}>{qrData}</h5>
            <div style={{border: '2px solid #e0e0e0', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
             <QRCode id={`qrCode-${maskedId}`} value={qrData} size={300} level={"L"} includeMargin={true}/>
             </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                    <button className="btn btn-primary mt-4" onClick={downloadQRCode}>{t('download_qr')} PNG </button>
                    <button className="btn btn-primary mt-4" onClick={downloadQRCodeAsSVG}>{t('download_qr')} SVG</button>
            </div>
        </div>
    );
};

export default QRCodeGeneratorLte;
