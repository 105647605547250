import React, { useState, useEffect } from 'react';
import QRCodeGenerator from './QRCodeGenerator';
import { deleteQrLabelCall } from 'features/qrLabel/deleteQrLabel';
import { updateQrLabelCall } from 'features/qrLabel/updateQrLabel';
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { createQrLabelCall } from 'features/qrLabel/createQrLabel';
import i18n from 'i18n';

const DatalistAltCS = ({ data, num, add, det}) => {
    const dispatch = useDispatch();
    const { qr_account, qr_label_list_load, language } = useSelector(state => state.user);
    const [selectedRow, setSelectedRow] = useState(null);
    const [editData, setEditData] = useState({});
    const { t } = useTranslation();
    const t_eng = i18n.getFixedT('en');
    const t_hrv = i18n.getFixedT('hr');
    const [lan, setLan] = useState("hr");
    const [listToShowHr, setListToShowHr] = useState([]);
    const [listToShowEn, setListToShowEn] = useState([]);
    const [listToShowSk, setListToShowSk] = useState([]);
    const [listToShowSv, setListToShowSv] = useState([]);
    const [listToShow, setListToShow] = useState([]);
    const [num_local, setNum_local] = useState(0);

    useEffect(() => {
        if (num) {
            setNum_local(num);
        }
        
    }, [dispatch, num]);

    useEffect(() => {
        if (data) {
            const groupedHr = data.filter(item => item.lang === 'hr');
            const groupedEn = data.filter(item => item.lang === 'en');
            const groupedSk = data.filter(item => item.lang === 'sk');
            const groupedSv = data.filter(item => item.lang === 'sv');

            const hrMap = new Map(groupedHr.map(item => [item.masked_id, item]));
            const enMap = new Map(groupedEn.map(item => [item.masked_id, item]));
            const skMap = new Map(groupedSk.map(item => [item.masked_id, item]));
            const svMap = new Map(groupedSv.map(item => [item.masked_id, item]));

            hrMap.forEach((hrItem, masked_id) => {
                if (!enMap.has(masked_id)) {
                    groupedEn.push({ ...hrItem});
                }
            });

            setListToShowHr(groupedHr);
            setListToShowEn(groupedEn);
            setListToShowSk(groupedSk);
            setListToShowSv(groupedSv);
        }
    }, [dispatch, data]);

    useEffect(() => {
        if (lan === 'hr') {
            setListToShow(listToShowHr);
        } else {
            setListToShow(listToShowEn);
        }
    }, [dispatch, lan, listToShowHr, listToShowEn]);

    const handleEdit = (item) => {
        setSelectedRow(item.id);
        setEditData({ ...item });
    };

    const onChange = e => {
        setEditData({ ...editData, [e.target.name]: e.target.value });
    };

    const saveChanges = () => {
        const payload = { ...editData };
        dispatch(updateQrLabelCall(payload));
        setSelectedRow(null);
    };

    const cancelEdit = () => {
        setSelectedRow(null);
    };

    const handleDelete = (id) => {
        const payload = { id: id };
        dispatch(deleteQrLabelCall(payload));
        setSelectedRow(null);
    };

    const changeLanguageHc = (lng) => {
        setLan(lng);
    };

    const createNewEntry = (entry) => {
        const { id, ...rest } = entry;
        const payload = { ...rest, lang: lan };
        dispatch(createQrLabelCall(payload));
    };

    function truncateText(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    }

    function truncateText2(text, maxLength) {
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength);
    }

    function retLangOrEn(hr, en, sk, sv) {
        let ret = en;

        if(language === "hr" && hr.length > 0){
            ret = hr;
        }

        if(language === "sk" && sk.length > 0){
            ret = sk;
        }

        if(language === "sv" && sv.length > 0){
            ret = sv;
        }

        return ret;
    };

    if (qr_label_list_load) {
        return (
            <div className="card" style={{ width: "100%", padding: "20px" }}>
                <div className="spinner-border text-primary mt-4" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        );
    }

    const totalCards = Math.max(num_local, listToShow.length);
    const placeholders = Array(totalCards - listToShow.length).fill({});

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px', justifyContent: 'center' }}>
            {listToShow.map(group => {
                const hrEntry = listToShowHr.find(item => item.masked_id === group.masked_id);
                const enEntry = listToShowEn.find(item => item.masked_id === group.masked_id);
                const skEntry = listToShowSk.find(item => item.masked_id === group.masked_id);
                const svEntry = listToShowSv.find(item => item.masked_id === group.masked_id);
                return (
                    <div key={group.masked_id} className="card" style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px', width: '310px', height:"550px", backgroundColor: "#e6fff2", border: '1px solid #e0e0e0', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{ padding: '10px' }}>
                            <h5 style={{ minHeight: "20px", color: "#001a00", marginBottom: "0px" }}>{selectedRow === group.id ? truncateText(editData.name, 18) : truncateText(retLangOrEn(hrEntry.name, enEntry.name, skEntry.name, svEntry.name), 18)}</h5>
                            <QRCodeGenerator maskedId={hrEntry?.masked_id || group.masked_id} name={truncateText2(retLangOrEn(hrEntry.name, enEntry.name, skEntry.name, svEntry.name), 18)} itemid={hrEntry?.id || group.id} det={det}/>
                        </div>
                    </div>
                );
            })}
            {placeholders.map((_, index) => (
                <div key={`placeholder-${index}`} className="card" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '20px', marginTop: '20px', width: '310px', height: '550px', border: '10px dashed #e0e0e0', borderRadius: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                    <button className="btn btn-primary" onClick={() => add()}>{t('add')}</button>
                </div>
            ))}
        </div>
    );
};

export default DatalistAltCS;
